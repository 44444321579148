import React, { useContext, useEffect, useState } from "react";
import { useNavigate, generatePath } from "react-router-dom";

import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";
import "./amanDashboard.css";

import { MdWifi } from "react-icons/md";
import { MdWifiOff } from "react-icons/md";
import generatorIcon from "./assets/generator-icon.png";
import boilerIcon from "./assets/boiler.png";
import energyIcon from "./assets/energy_meter.png";
import DangerStatus from "../../dashboard/thresholdStatus/DangerStatus";
import SafeStatus from "../../dashboard/thresholdStatus/SafeStatus";
import WarningStatus from "../../dashboard/thresholdStatus/WarningStatus";

import { useGetAmanDashboardCardDataQuery } from "../../../features/aman-dashboard/amanDashboardApi";
import { useAmanGeneratorListMutation } from "../../../features/aman-dashboard/amanDashboardApi";
import { useAmanEnergyMeterListMutation } from "../../../features/aman-dashboard/amanDashboardApi";
import { useAmanBoilerListMutation } from "../../../features/aman-dashboard/amanDashboardApi";

function AmanDashboard() {
  useTitle("Aman Dashboard");
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  const [generatorCompanyList, setGeneratorCompanyList] = useState([]);
  const [generatorDataList, setGeneratorDataList] = useState([]);
  const [generatorFormData, setGeneratorFormData] = useState({});

  const [energyCompanyList, setEnergyCompanyList] = useState([]);
  const [energyDataList, setEnergyDataList] = useState([]);
  const [energyFormData, setEnergyFormData] = useState({});

  const [boilerCompanyList, setBoilerCompanyList] = useState([]);
  const [boilerDataList, setBoilerDataList] = useState([]);
  const [boilerFormData, setBoilerFormData] = useState({});

  const { data, isLoading } = useGetAmanDashboardCardDataQuery();

  const [amanGeneratorList] = useAmanGeneratorListMutation();

  const [amanEnergyMeterList] = useAmanEnergyMeterListMutation();

  const [amanBoilerList] = useAmanBoilerListMutation();

  useEffect(() => {
    amanGeneratorList()
      .unwrap()
      .then((payload) => {
        // console.log(payload.company);
        setGeneratorCompanyList(payload?.company);
        setGeneratorDataList(payload?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [amanGeneratorList]);

  useEffect(() => {
    amanBoilerList()
      .unwrap()
      .then((payload) => {
        // console.log(payload.company);
        setBoilerCompanyList(payload?.company);
        setBoilerDataList(payload?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [amanBoilerList]);

  useEffect(() => {
    amanEnergyMeterList()
      .unwrap()
      .then((payload) => {
        setEnergyCompanyList(payload?.company);
        setEnergyDataList(payload?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [amanEnergyMeterList]);

  const generatorDataChangeHandler = (e) => {
    if (e.target.value == "") {
      delete generatorFormData[e.target.name];
    } else {
      setGeneratorFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    }
  };
  const energyDataChangeHandler = (e) => {
    if (e.target.value == "") {
      delete energyFormData[e.target.name];
    } else {
      setEnergyFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const boilerDataChangeHandler = (e) => {
    if (e.target.value == "") {
      delete boilerFormData[e.target.name];
    } else {
      setBoilerFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const generatorSearchHandler = (e) => {
    e.preventDefault();
    amanGeneratorList(generatorFormData)
      .unwrap()
      .then((payload) => {
        // console.log(payload.company);
        setGeneratorDataList(payload?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const boilerSearchHandler = (e) => {
    e.preventDefault();
    amanBoilerList(boilerFormData)
      .unwrap()
      .then((payload) => {
        // console.log(payload.company);
        setBoilerDataList(payload?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const energySearchHandler = (e) => {
    e.preventDefault();
    amanEnergyMeterList(energyFormData)
      .unwrap()
      .then((payload) => {
        setEnergyDataList(payload?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const energyMeterClickHandler = (id) => {
    const path = generatePath("/energy-meter/:meterId", {
      meterId: id,
    });
    // navigate(path);
    const url = window.location.origin + path;
    window.open(url, "_blank");
  };
  const generatorClickHandler = (combined_id) => {
    const path = generatePath("/generator/dashboard/:combined_id", {
      combined_id: combined_id,
    });
    // navigate(path);
    const url = window.location.origin + path;
    window.open(url, "_blank");
  };
  const boilerCompanyClickHandler = (companyId) => {
    const path = generatePath("/boiler/dashboard/:companyId", {
      companyId: companyId,
    });
    // navigate(path);
    const url = window.location.origin + path;
    window.open(url, "_blank");
  };
  const generatorCompanyClickHandler = (companyId) => {
    const path = generatePath("/dashboard/generator/:companyId", {
      companyId: companyId,
    });
    // navigate(path);
    const url = window.location.origin + path;
    window.open(url, "_blank");
  };
  const energyCompanyClickHandler = (companyId) => {
    const path = generatePath("/dashboard/energy/:companyId", {
      companyId: companyId,
    });
    // navigate(path);
    const url = window.location.origin + path;
    window.open(url, "_blank");
  };

  return (
    <div className={`${theme}`}>
      <div className="row">
        <div className="col-md-4">
          <div className="card theme-background">
            <div className="aman-card-title theme-text">Generator</div>
            <div className="aman-body">
              <div className="aman-icon">
                <img src={generatorIcon}></img>
              </div>
              <div className="aman-text theme-text">
                <div className="aman-text-container">
                  <div className="text-only">Total Generator</div>
                  <div className="text-value">
                    {" "}
                    {data?.generator?.total_generator || 0}
                  </div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    <div className="aman-status">
                      <SafeStatus />
                    </div>
                    <div className="text-only">Generator On</div>
                  </div>
                  <div className="text-value">
                    {" "}
                    {data?.generator?.generator_on || 0}
                  </div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    <div className="aman-status">
                      <DangerStatus />
                    </div>
                    <div className="text-only">Generator Off</div>
                  </div>
                  <div className="text-value">
                    {" "}
                    {data?.generator?.generator_off || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card theme-background">
            <div className="aman-card-title theme-text">Boiler</div>
            <div className="aman-body">
              <div className="aman-icon">
                <img src={boilerIcon}></img>
              </div>
              <div className="aman-text theme-text">
                <div className="aman-text-container">
                  <div className="text-only">Total Boiler</div>
                  <div className="text-value">
                    {data?.boiler?.total_boiler || 0}
                  </div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    <div className="aman-status">
                      <SafeStatus />
                    </div>
                    <div className="text-only">Boiler On</div>
                  </div>
                  <div className="text-value">
                    {data?.boiler?.boiler_on || 0}
                  </div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    <div className="aman-status">
                      <DangerStatus />
                    </div>
                    <div className="text-only">Boiler Off</div>
                  </div>
                  <div className="text-value">
                    {data?.boiler?.boiler_off || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="card theme-background">
            <div className="aman-card-title theme-text">Energy Meter</div>
            <div className="aman-body">
              <div className="aman-icon">
                <img src={energyIcon}></img>
              </div>
              <div className="aman-text theme-text">
                <div className="aman-text-container">
                  <div className="text-only t">Total Meter</div>
                  <div className="text-value t">
                    {" "}
                    {data?.energy_meter?.device_count || 0}
                  </div>
                </div>
                <div className="aman-text-container ">
                  <div className="text-only-container">
                    <div className="aman-status">
                      <SafeStatus />
                    </div>
                    <div className="text-only ">Active Energy</div>
                  </div>
                  <div className="text-value ">
                    {data?.energy_meter?.active_energy_consumption || 0}
                  </div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    <div className="aman-status">
                      <DangerStatus />
                    </div>
                    <div className="text-only">Reactive Energy</div>
                  </div>
                  <div className="text-value">
                    {data?.energy_meter?.reactive_energy_consumption || 0}
                  </div>
                </div>
                <div className="aman-text-container">
                  <div className="text-only-container">
                    <div className="aman-status">
                      <WarningStatus />
                    </div>
                    <div className="text-only">Apparent Energy</div>
                  </div>
                  <div className="text-value">
                    {" "}
                    {data?.energy_meter?.apparent_energy_consumption || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="generator-list-container">
          <div
            className="theme-text"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Generator List
          </div>
          <div className="">
            <form onSubmit={(e) => generatorSearchHandler(e)}>
              <div className="row">
                <div className="col-md-2 date-search-submit-btn">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="company_id"
                    onChange={generatorDataChangeHandler}
                  >
                    <option value="" selected>
                      Select Company
                    </option>
                    {generatorCompanyList?.map((companyInfo) => {
                      return (
                        <option value={companyInfo.id}>
                          {companyInfo?.company_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-2 date-search-submit-btn">
                  <button type="submit" class="btn btn-primary col-md-12">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="list-table-container">
            <table className="list-table">
              <tr className="theme-text">
                <th>Status</th>
                <th>Company Name</th>
                <th>Generator Name</th>
                <th>Address</th>
                <th>Oil Level</th>
                <th>Oil Consumption(month)</th>
              </tr>

              {generatorDataList?.map((listData) => {
                return (
                  <tr className="theme-text">
                    <td
                      style={{
                        display: "flex",
                        // alignItems: "center",
                        border: "none",
                        justifyContent: "center",
                      }}
                    >
                      {listData?.generator_status == "Off" ? (
                        <DangerStatus />
                      ) : (
                        <SafeStatus />
                      )}
                    </td>
                    <td
                      className="clickable-td"
                      onClick={() =>
                        generatorCompanyClickHandler(listData?.company_id)
                      }
                    >
                      {listData?.company_name}
                    </td>
                    <td
                      className="clickable-td"
                      onClick={() =>
                        generatorClickHandler(listData?.combined_id)
                      }
                    >
                      {listData?.device_name}
                    </td>
                    <td>{listData?.company_address}</td>
                    <td>{listData?.current_oil_level}</td>
                    <td>{listData?.oil_consumption_current_month}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <div className="boiler-list-container">
          <div
            className="theme-text"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Boiler List
          </div>
          <div className="">
            <form onSubmit={(e) => boilerSearchHandler(e)}>
              <div className="row">
                <div className="col-md-2 date-search-submit-btn">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="company_id"
                    onChange={boilerDataChangeHandler}
                  >
                    <option value="" selected>
                      Select Company
                    </option>
                    {boilerCompanyList?.map((companyInfo) => {
                      return (
                        <option value={companyInfo.id}>
                          {companyInfo?.company_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-2 date-search-submit-btn">
                  <button type="submit" class="btn btn-primary col-md-12">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="list-table-container">
            <table className="list-table">
              <tr className="theme-text">
                <th>Status</th>
                <th>Company Name</th>
                <th>Device Name</th>
                <th>Address</th>
              </tr>

              {boilerDataList?.map((listData) => {
                return (
                  <tr className="theme-text">
                    <td
                      style={{
                        display: "flex",
                        // alignItems: "center",
                        border: "none",
                        justifyContent: "center",
                      }}
                    >
                      {listData?.boiler_status == "Off" ? (
                        <DangerStatus />
                      ) : (
                        <SafeStatus />
                      )}
                    </td>
                    <td
                      className="clickable-td"
                      onClick={() =>
                        boilerCompanyClickHandler(listData?.company_id)
                      }
                    >
                      {listData?.company_name}
                    </td>
                    <td
                    // className="clickable-td"
                    // onClick={() =>
                    //   energyMeterClickHandler(listData?.meter_id)
                    // }
                    >
                      {listData?.device_name}
                    </td>
                    <td>{listData?.company_address}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        {/* <div className="boiler-list-container">
          <div
            className="theme-text"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Energy Meter List
          </div>
          <div className="">
            <form onSubmit={(e) => energySearchHandler(e)}>
              <div className="row">
                <div className="col-md-2 date-search-submit-btn">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="company_id"
                    onChange={energyDataChangeHandler}
                  >
                    <option value="" selected>
                      Select Company
                    </option>
                    {energyCompanyList?.map((companyInfo) => {
                      return (
                        <option value={companyInfo.id}>
                          {companyInfo?.company_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-2 date-search-submit-btn">
                  <button type="submit" class="btn btn-primary col-md-12">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="list-table-container">
            <table className="list-table">
              <tr className="theme-text">
                <th>Status</th>
                <th>Company Name</th>
                <th>Meter Name</th>
                <th>Address</th>
                <th>Active Energy</th>
                <th>Reactive Energy</th>
                <th>Apparent Energy</th>
              </tr>

              {energyDataList?.map((listData) => {
                return (
                  <tr className="theme-text">
                    <td
                      style={{
                        display: "flex",
                        // alignItems: "center",
                        border: "none",
                        justifyContent: "center",
                      }}
                    >
                      {listData?.networkStatus == "0" ? (
                        <DangerStatus />
                      ) : (
                        <SafeStatus />
                      )}
                    </td>
                    <td
                      className="clickable-td"
                      onClick={() =>
                        energyCompanyClickHandler(listData?.company_id)
                      }
                    >
                      {listData?.company_name}
                    </td>
                    <td
                      className="clickable-td"
                      onClick={() =>
                        energyMeterClickHandler(listData?.meter_id)
                      }
                    >
                      {listData?.device_name}
                    </td>
                    <td>{listData?.company_address}</td>
                    <td>{listData?.active_energy_consumption}</td>
                    <td>{listData?.reactive_energy_consumption}</td>
                    <td>{listData?.apparent_energy_consumption}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AmanDashboard;
