import CompB from "../components/CompB";
import CompC from "../components/CompC";
import BaseLayout from "../layout/BaseLayout";
import CompD from "../components/CompD";
import { Outlet } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import { FaBeer, FaDoorClosed, FaBell, FaCity, FaFish } from "react-icons/fa";
import { RiDashboardFill, RiSensorFill, RiDeviceFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { AiFillControl } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";

import { MdAssignment } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import { AiTwotoneNotification } from "react-icons/ai";
import { GiWaterTank } from "react-icons/gi";
import { CgSmartHomeBoiler } from "react-icons/cg";

import TestComponent from "../components/TestComponent";
import AlertSettings from "../components/alert/alertSettings";
import CreateSensor from "../components/sensor/createSensor";
// import SensorMap from "../components/sensor/sensorMap";
import CreatePremises from "../components/premises/createPremises";
import ListPremises from "../components/premises/listPremises";
import DeviceConfiguration from "../components/device/deviceConfiguration";
import RoomConfiguration from "../components/premises/roomConfiguration";
// import Building from "../components/premises/building";
import { ImDatabase } from "react-icons/im";
import DataLogMain from "../components/dataLog/DataLogMain";
import RoomDetailsMain from "../components/dashboard/roomDetails/RoomDetailsMain";
import BasicTableComponent from "../components/BasicTableComponent";
import Login from "../components/auth/Login";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import Logout from "../components/auth/Logout";
import Premises from "../components/premises/premisesCreate/Premises";
import EnergyDashboard from "../components/energyMeter/EnergyDashboard";
import { ImPower } from "react-icons/im";
import EnergyDetailsMain from "../components/energyMeter/details/EnergyDetailsMain";
import Rooms from "../components/rooms/Rooms";
import Building from "../components/building/Building";
import SlaveAssign from "../components/slaveAssign/SlaveAssign";
import DeviceConfig from "../components/device-config/DeviceConfig";
import SensorMap from "../components/sensor-map/SensorMap";
import MotorControl from "../components/motor-control/MotorControl";
import NotificationMain from "../components/notification/NotificationMain";
import Tank from "../components/Tank/Tank";
import Fish from "../components/fish/Fish";
import FishTankAssign from "../components/fish/FishTankAssign";
import FishDesease from "../components/fish/FishDesease";
import FishFeed from "../components/fish/FishFeed";
import FishDailyFeed from "../components/fish/FishDailyFeed";
import FishLog from "../components/fish/FishLog";
import DailyFishLogMain from "../components/fish/fishLog/dailyFishLog/DailyFishLogMain";
import MonthlyFishLogMain from "../components/fish/fishLog/monthlyFishLog/MonthlyFishLogMain";
import GrossFishLogMain from "../components/fish/fishLog/grossFishLog/GrossFishLogMain";
import GeneratorDashboard from "../components/generator/dashboard/GeneratorDashboard";
import DashboardDetailsMain from "../components/generator/dashboardDetails/DashboardDetailsMain";
import GeneratorDataLogMain from "../components/generator/dataLog/GeneratorDataLogMain";
import InformationUpdate from "../components/generator/informationUpdate/InformationUpdate";
import DaywiseConsumption from "../components/generator/daywiseOilConsumptionReport/DaywiseConsumption";
import DataTableWithTotalCount from "../components/generator/daywiseOilConsumptionReport/DataTableWithTotalCount";
import OilConsumptionReport from "../components/generator/oilConsumptionReport/OilConsumptionReport";
import ChartReportsMain from "../components/generator/chartReports/ChartReportsMain";
import AnomalyReport from "../components/generator/anomalyReport/AnomalyReport";
import EnergyMeterDashboardUpdated from "../components/energyMeterUpdated/dashboard/EnergyMeterDashboardUpdated";
import EnergyMeterDashboardDetails from "../components/energyMeterUpdated/dashboard_details/EnergyMeterDashboardDetails";
import EnergyMeterDataLogMain from "../components/energyMeterUpdated/dataLog/EnergyMeterDataLogMain";
import EnergyConsumptionGraphMain from "../components/energyMeterUpdated/energy_consumption_graph/EnergyConsumptionGraphMain";
import MaintenanceReport from "../components/generator/maintenanceReport/MaintenanceReport";
import Billing from "../components/energyMeterUpdated/billing/Billing";
import ColumbiaDashboard from "../components/generator/columbiaDashboard/ColumbiaDashboard";
import AmanDashboard from "../components/aman-dashboard/dashboard/AmanDashboard";
import AdjustedReport from "../components/generator/adjustedReport/AdjustedReport";
import CompanyCreate from "../components/aman-dashboard/companyCreate/CompanyCreate";
import CompanyAssign from "../components/aman-dashboard/companyAssign/CompanyAssign";
import ConsumptionReport from "../components/generator/consumptionReport/ConsumptionReport";
import BoilerDashboard from "../components/boiler/dashboard/BoilerDashboard";
import BoilerDataLogMain from "../components/boiler/dataLog/BoilerDataLogMain";
import BoilerCompanyDashboard from "../components/aman-dashboard/boilerCompanyDashboard/BoilerCompanyDashboard";
import GeneratorCompanyDashboard from "../components/aman-dashboard/generatorCompanyDashboard/GeneratorCompanyDashboard";
import EnergyCompanyDashboard from "../components/aman-dashboard/energyCompanyDashboard/EnergyCompanyDashboard";
import OilInReport from "../components/generator/oilInReport/OilInReport";
import BoilerOperatingReport from "../components/boiler/boilerOperatingReport/BoilerOperatingReport";
import GltcFuleDashboard from "../components/gltc-fuel/dashboard/GltcFuleDashboard";

//first route shouldnot have children might create issue on sidebar

export const routes = [
  {
    title: "Default",
    path: "/",
    icon: <RiDashboardFill />,
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    title: "Login",
    path: "/login",
    icon: <RiDashboardFill />,
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  // {
  //   title: "Dashboard",
  //   path: "/dashboard",
  //   icon: <RiDashboardFill />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       <Dashboard />
  //     </PrivateRoute>
  //   ),
  // },
  // // {
  // //   title: "Energy Dashboard",
  // //   path: "/energy_meter_dashboard",
  // //   icon: <ImPower />,
  // //   visibility: true,
  // //   element: (
  // //     <PrivateRoute>
  // //       {" "}
  // //       <EnergyDashboard />
  // //     </PrivateRoute>
  // //   ),
  // // },
  // // // {
  // // //   title: "Motor Control",
  // // //   path: "/motor-control",
  // // //   icon: <AiFillControl />,
  // // //   visibility: true,
  // // //   element: (
  // // //     <PrivateRoute>
  // // //       <MotorControl />
  // // //     </PrivateRoute>
  // // //   ),
  // // // },
  // {
  //   title: "Data Log",
  //   path: "/data_log",
  //   icon: <ImDatabase />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       {" "}
  //       <DataLogMain />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Notification Log",
  //   path: "/notification",
  //   icon: <AiTwotoneNotification />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       {" "}
  //       <NotificationMain />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Room Details",
  //   path: "/dashboard/:roomId",
  //   icon: <ImDatabase />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <RoomDetailsMain />
  //     </PrivateRoute>
  //   ),
  // },

  // // // {
  // // //   title: "Energy Meter Details",
  // // //   path: "/energy_meter_dashboard/:meterId",
  // // //   icon: <ImDatabase />,
  // // //   visibility: false,
  // // //   element: (
  // // //     <PrivateRoute>
  // // //       <EnergyDetailsMain />
  // // //     </PrivateRoute>
  // // //   ),
  // // // },
  // {
  //   title: "Alert Settings",
  //   path: "/alert",
  //   icon: <FaBell />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       <AlertSettings />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Sensor Management",
  //   path: "/sensor",
  //   icon: <RiSensorFill />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <CreateSensor />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Sensor Management",
  //   path: "/sensor",
  //   icon: <RiSensorFill />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "Create Sensor",
  //       path: "create-sensor",
  //       // icon: <FaBeer />,
  //       element: (
  //         <PrivateRoute>
  //           <CreateSensor />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },

  // {
  //   title: "Premises  ",
  //   path: "/premises",
  //   icon: <FaCity />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <Premises />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Premises  ",
  //   path: "/premises",
  //   icon: <FaCity />,
  //   visibility: true,

  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "Create Premises ",
  //       path: "create-premises",
  //       icon: <FaBeer />,
  //       element: (
  //         <PrivateRoute>
  //           <Premises />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Create Building",
  //       path: "building-create",
  //       icon: <FaBeer />,
  //       element: (
  //         <PrivateRoute>
  //           <Building />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Create Room ",
  //       path: "room-create",
  //       icon: <FaBeer />,
  //       element: (
  //         <PrivateRoute>
  //           <Rooms />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "Slave Assign",
  //   path: "/slave-assign",
  //   icon: <MdAssignment />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <SlaveAssign />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Slave Assign",
  //   path: "/slave-assign",
  //   icon: <MdAssignment />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "Device Assign",
  //       path: "device-assign",
  //       element: (
  //         <PrivateRoute>
  //           <SlaveAssign />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Sensor Map",
  //       path: "sensor-map",
  //       element: (
  //         <PrivateRoute>
  //           <SensorMap />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },

  // {
  //   title: "Tank Create",
  //   path: "/tank-create",
  //   icon: <GiWaterTank />,
  //   visibility: true,
  //   element: <Tank />,
  // },
  // {
  //   title: "Fish",
  //   path: "/fish",
  //   icon: <FaFish />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<Fish />},
  //     {
  //       title: "Fish Create",
  //       path: "fish-create",
  //       element: (
  //         <PrivateRoute>
  //           <Fish />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Assign",
  //       path: "fish-assign",
  //       element: (
  //         <PrivateRoute>
  //           <FishTankAssign />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Desease",
  //       path: "fish-desease",
  //       element: (
  //         <PrivateRoute>
  //           <FishDesease />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Feed",
  //       path: "fish-feed",
  //       element: (
  //         <PrivateRoute>
  //           <FishFeed />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Daily Feed",
  //       path: "fish-daily-feed",
  //       element: (
  //         <PrivateRoute>
  //           <FishDailyFeed />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Log",
  //       path: "fish-log",
  //       element: (
  //         <PrivateRoute>
  //           <FishLog />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "Fish Report",
  //   path: "/fish-report",
  //   icon: <MdAssignment />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<CompB/>},

  //     // {
  //     //   title: "Daily Fish with Child",
  //     //   path: "daily-fish-report-with-child",

  //     //   children: [

  //     //     {
  //     //       title: "Monthly Fish Report",
  //     //       path: "monthly-fish-report",
  //     //       element: (
  //     //         <PrivateRoute>
  //     //           <MonthlyFishLogMain />
  //     //         </PrivateRoute>
  //     //       ),
  //     //       // loader: teamLoader,
  //     //     },
  //     //   ],
  //     // },
  //     // {
  //     //   title: "Daily Fish with Child",
  //     //   path: "daily-fish-report-with-child",

  //     //   children: [

  //     //     {
  //     //       title: "Monthly Fish Copy",
  //     //       path: "monthly-fish-report",
  //     //       element: (
  //     //         <PrivateRoute>
  //     //           <MonthlyFishLogMain />
  //     //         </PrivateRoute>
  //     //       ),
  //     //       // loader: teamLoader,
  //     //     },
  //     //     {
  //     //       title: "Monthly Fish Copy",
  //     //       path: "monthly-fish-report",
  //     //       element: (
  //     //         <PrivateRoute>
  //     //           <MonthlyFishLogMain />
  //     //         </PrivateRoute>
  //     //       ),
  //     //       // loader: teamLoader,
  //     //     },
  //     //   ],
  //     // },

  //     {
  //       title: "Daily Fish Report",
  //       path: "daily-fish-report",
  //       element: (
  //         <PrivateRoute>
  //           <DailyFishLogMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Monthly Fish Report",
  //       path: "monthly-fish-report",
  //       element: (
  //         <PrivateRoute>
  //           <MonthlyFishLogMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Gross Fish Report",
  //       path: "gross-fish-report",
  //       element: (
  //         <PrivateRoute>
  //           <GrossFishLogMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  {
    title: "Aman Dashboard",
    path: "/aman",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <AmanDashboard />
      </PrivateRoute>
    ),
  },
  {
    title: "Dashboard",
    path: "/aman",
    icon: <ImDatabase />,
    visibility: true,
    children: [
      {
        title: "Dashboard",
        path: "dashboard",
        element: (
          <PrivateRoute>
            <AmanDashboard />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Company Create",
        path: "company-create",
        element: (
          <PrivateRoute>
            <CompanyCreate />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Company Assign",
        path: "company-assign",
        element: (
          <PrivateRoute>
            <CompanyAssign />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
    ],
  },
  {
    title: "Energy Meter",
    path: "/energy-meter",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <EnergyMeterDashboardUpdated />
      </PrivateRoute>
    ),
  },

  {
    title: "Energy Meter",
    path: "/energy-meter",
    icon: <ImPower />,
    visibility: true,
    children: [
      {
        title: "Dashboard",
        path: "dashboard",
        element: (
          <PrivateRoute>
            <EnergyMeterDashboardUpdated />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Data Log",
        path: "data-log",
        element: (
          <PrivateRoute>
            <EnergyMeterDataLogMain />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Energy Consumption",
        path: "energy-consumption",
        element: (
          <PrivateRoute>
            <EnergyConsumptionGraphMain />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      // {
      //   title: "Billing",
      //   path: "billing",
      //   element: (
      //     <PrivateRoute>
      //       <Billing />
      //     </PrivateRoute>
      //   ),
      //   // loader: teamLoader,
      // },
    ],
  },
  {
    title: "Energy Meter Details",
    path: "/energy-meter/:meterId",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <EnergyMeterDashboardDetails />
      </PrivateRoute>
    ),
  },

  {
    title: "Generator",
    path: "/generator",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <GeneratorDashboard />
        {/* <ColumbiaDashboard /> */}
      </PrivateRoute>
    ),
  },
  {
    title: "Generator",
    path: "/generator",
    icon: <AiFillSetting />,
    visibility: true,
    children: [
      {
        title: "Dashboard",
        path: "dashboard",
        element: (
          <PrivateRoute>
            <GeneratorDashboard />
            {/* <ColumbiaDashboard /> */}
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Data Log",
        path: "data-log",
        element: (
          <PrivateRoute>
            <GeneratorDataLogMain />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },

      // {
      //   title: "Running Hour Report ",
      //   path: "runningHour-report",
      //   element: (
      //     <PrivateRoute>
      //       <DaywiseConsumption />
      //     </PrivateRoute>
      //   ),
      //   // loader: teamLoader,
      // },
      // {
      //   title: "Consumption Report",
      //   path: "consumption-report",
      //   element: (
      //     <PrivateRoute>
      //       <ConsumptionReport />
      //     </PrivateRoute>
      //   ),
      //   // loader: teamLoader,
      // },
      {
        title: "Operating Report",
        path: "operating-report",
        element: (
          <PrivateRoute>
            <OilConsumptionReport />
            {/* <DataTableWithTotalCount /> */}
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      // {
      //   title: "Oil In Report",
      //   path: "oil-in-report",
      //   element: (
      //     <PrivateRoute>
      //       <OilInReport />
      //     </PrivateRoute>
      //   ),
      //   // loader: teamLoader,
      // },
      // {
      //   title: "Adjusted Report",
      //   path: "adjusted-report",
      //   element: (
      //     <PrivateRoute>
      //       <AdjustedReport />
      //     </PrivateRoute>
      //   ),
      //   // loader: teamLoader,
      // },
      {
        title: "Graphical Dashboard",
        path: "graphical-dashboard",
        element: (
          <PrivateRoute>
            <ChartReportsMain />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      // {
      //   title: "Anomaly Report",
      //   path: "anomaly-report",
      //   element: (
      //     <PrivateRoute>
      //       <AnomalyReport />
      //     </PrivateRoute>
      //   ),
      //   // loader: teamLoader,
      // },
      {
        title: "Update Info",
        path: "update-info",
        element: (
          <PrivateRoute>
            <InformationUpdate />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      // {
      //   title: "Maintenance Report ",
      //   path: "maintenance-report",
      //   element: (
      //     <PrivateRoute>
      //       <MaintenanceReport />
      //     </PrivateRoute>
      //   ),
      //   // loader: teamLoader,
      // },
    ],
  },
  {
    title: "Generator Dashboard Details",
    path: "/generator/dashboard/:combined_id",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <DashboardDetailsMain />
      </PrivateRoute>
    ),
  },
  {
    title: "Boiler Company Wise Dashboard",
    path: "/boiler/dashboard/:companyId",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <BoilerCompanyDashboard />
      </PrivateRoute>
    ),
  },
  {
    title: "Generator Company Wise Dashboard",
    path: "/dashboard/generator/:companyId",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <GeneratorCompanyDashboard />
      </PrivateRoute>
    ),
  },
  {
    title: "EnergyMeter Company Wise Dashboard",
    path: "/dashboard/energy/:companyId",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <EnergyCompanyDashboard />
      </PrivateRoute>
    ),
  },

  //boiler routes
  {
    title: "Boiler",
    path: "/boiler",
    icon: <CgSmartHomeBoiler />,
    visibility: false,
    element: (
      <PrivateRoute>
        <BoilerDashboard />
      </PrivateRoute>
    ),
  },
  {
    title: "Boiler",
    path: "/boiler",
    icon: <CgSmartHomeBoiler />,
    visibility: true,
    children: [
      {
        title: "Dashboard",
        path: "dashboard",
        element: (
          <PrivateRoute>
            <BoilerDashboard />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      // {
      //   title: "Data Log",
      //   path: "data-log",
      //   element: (
      //     <PrivateRoute>
      //       {/* <GeneratorDataLogMain /> */}
      //       <BoilerDataLogMain />
      //     </PrivateRoute>
      //   ),
      //   // loader: teamLoader,
      // },
      {
        title: "Operating Report",
        path: "operating-report",
        element: (
          <PrivateRoute>
            <BoilerOperatingReport />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
    ],
  },

  // {
  //   title: "Gltc fuel Dashboard",
  //   path: "/gltc-fuel",
  //   icon: <CgSmartHomeBoiler />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <GltcFuleDashboard />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Gltc fuel Dashboard",
  //   path: "/gltc-fuel",
  //   icon: <CgSmartHomeBoiler />,
  //   visibility: true,
  //   children: [
  //     {
  //       title: "Dashboard",
  //       path: "dashboard",
  //       element: (
  //         <PrivateRoute>
  //           <GltcFuleDashboard />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },

  // {
  //   title: "Device Configuration",
  //   path: "/device-config",
  //   icon: <RiDeviceFill />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       <DeviceConfig />
  //     </PrivateRoute>
  //   ),
  // },
  {
    title: "logout",
    path: "/logout",
    icon: <FiLogOut />,
    visibility: true,
    element: <Logout />,
  },
  {
    path: "*",
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  }, //to redirect unknown routes during login
];
