import React, { useContext, useEffect } from "react";
import { useNavigate, generatePath, useParams } from "react-router-dom";

import DashboardCard from "./DashboardCard";
import "./style.css";
import { ThemeContext } from "../../../context/ThemeContext";

import ReactLoading from "react-loading";

import mqtt from "mqtt/dist/mqtt";

import useTitle from "../../../hooks/useTitle";
import { useGetAmanBoilerCompanyDashboardDataQuery } from "../../../features/aman-dashboard/amanCompanyDashboard";
import { useLazyGetAmanBoilerCompanyDashboardDataQuery } from "../../../features/aman-dashboard/amanCompanyDashboard";

function BoilerCompanyDashboard() {
  useTitle("Boiler Dashboard");
  const theme = useContext(ThemeContext);
  const { companyId } = useParams();

  const { data: boilerDataGet, isLoading } =
    useGetAmanBoilerCompanyDashboardDataQuery(companyId);
  const [getAmanBoilerCompanyDashboardData, results] =
    useLazyGetAmanBoilerCompanyDashboardDataQuery();

  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8081,
  };

  let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);
  useEffect(() => {
    client.subscribe("/boiler/data");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      getAmanBoilerCompanyDashboardData(companyId);
    });
  }, [client]);
  const navigate = useNavigate();
  const generatorClickHandler = (combined_id) => {
    const path = generatePath("/generator/dashboard/:combined_id", {
      combined_id: combined_id,
    });
    navigate(path);
  };

  // let generatorData = [];
  let boilerData = [];

  let element = "";

  if (isLoading) {
    element = (
      <div className="container loading-center">
        <ReactLoading type="spin" color="#6d6dae" height={100} width={50} />
      </div>
    );
  }
  if (!isLoading) {
    // generatorData = generatorDataGet?.data;
    // if (results?.data) {
    //   generatorData = results?.data?.data;
    // }

    boilerData = boilerDataGet?.data;
    if (results?.data) {
      boilerData = results?.data?.data;
    }

    element = (
      <>
        {boilerData ? (
          boilerData.map((data) => {
            return (
              <div
                className="col-md-6 col-lg-6 col-xl-3"
                // onClick={() => generatorClickHandler(data?.combined_id)}
              >
                <DashboardCard data={data} />
              </div>
            );
          })
        ) : (
          <div className="theme-text">No Data Available</div>
        )}
      </>
    );
  }

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="row">
          {/* <div className="col-md-6 col-lg-6 col-xl-4">
            <DashboardCard />
          </div> */}
          {element}
          {/* <FluidMeter /> */}
        </div>
      </div>
    </div>
  );
}

export default BoilerCompanyDashboard;
