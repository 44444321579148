import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";
import { useGetBoilerDashboardDataQuery } from "../../../features/boiler/boilerDashboardApi";
import { useLazyGetBoilerDashboardDataQuery } from "../../../features/boiler/boilerDashboardApi";

import ReactLoading from "react-loading";

import mqtt from "mqtt/dist/mqtt";
import GltcDashboardCard from "./GltcDashboardCard";

function GltcFuleDashboard() {
  useTitle("Gltc fuel Dashboard");
  const theme = useContext(ThemeContext);
  // const { data: generatorDataGet, isLoading } =
  //   useGetGeneratorDashboardDataQuery();
  // const [getGeneratorDashboardData, results] =
  //   useLazyGetGeneratorDashboardDataQuery();

  const { data: boilerDataGet, isLoading } = useGetBoilerDashboardDataQuery();
  const [getBoilerDashboardData, results] =
    useLazyGetBoilerDashboardDataQuery();

  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8081,
  };

  let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);
  useEffect(() => {
    client.subscribe("/boiler/data");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      // getGeneratorDashboardData();
      getBoilerDashboardData();
    });
  }, [client]);

  // let generatorData = [];
  let boilerData = [];

  let element = "";

  if (isLoading) {
    element = (
      <div className="container loading-center">
        <ReactLoading type="spin" color="#6d6dae" height={100} width={50} />
      </div>
    );
  }
  if (!isLoading) {
    // generatorData = generatorDataGet?.data;
    // if (results?.data) {
    //   generatorData = results?.data?.data;
    // }

    boilerData = boilerDataGet?.data;
    if (results?.data) {
      boilerData = results?.data?.data;
    }

    element = (
      <>
        {boilerData ? (
          boilerData.map((data) => {
            return (
              <div
                className="col-md-6 col-lg-6 col-xl-4"
                // onClick={() => generatorClickHandler(data?.combined_id)}
              >
                <GltcDashboardCard data={data} />
              </div>
            );
          })
        ) : (
          <div className="theme-text">No Data Available</div>
        )}
      </>
    );
  }

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="row">
          {/* <div className="col-md-6 col-lg-6 col-xl-4">
            <DashboardCard />
          </div> */}
          {element}
          {/* <FluidMeter /> */}
        </div>
      </div>
    </div>
  );
}

export default GltcFuleDashboard;
