import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

// import "./style/dashboard.css";
import ReactLoading from "react-loading";
import timeAgo from "../../../helper/timeAgo";
import mqtt from "mqtt/dist/mqtt";
import { Link, useNavigate } from "react-router-dom";
import { generatePath } from "react-router-dom";

import { useEnergyDashboardDataFetchQuery } from "../../../features/energy-meter/energyMeterUpdatedDashboardApi";
import { useLazyEnergyDashboardDataFetchQuery } from "../../../features/energy-meter/energyMeterUpdatedDashboardApi";
import "./styles.css";
import useTitle from "../../../hooks/useTitle";
import { TbListDetails } from "react-icons/tb";
import PieChart from "./PieChart";

import { useDashboardPieChartQuery } from "../../../features/energy-meter/energyMeterUpdatedDashboardApi";
import { useCarbonEmissionsValueQuery } from "../../../features/energy-meter/energyMeterUpdatedDashboardApi";

function EnergyMeterDashboardUpdated() {
  useTitle("Energy Dashboard");
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  //   let { data, isLoading } = useGetEnergyDashboardDataQuery();

  let { data, isLoading } = useEnergyDashboardDataFetchQuery();

  //   const [getEnergyDashboardData, results] =
  //     useLazyGetEnergyDashboardDataQuery();
  const [energyDashboardDataFetch, results] =
    useLazyEnergyDashboardDataFetchQuery();

  const { data: pieChartData, isLoading: pieChartLoading } =
    useDashboardPieChartQuery();

  const { data: carbonEmmisions } = useCarbonEmissionsValueQuery();
  console.log(carbonEmmisions);
  let activeEnergyPieData = [];
  let reactiveEnergyPieData = [];
  let apparentEnergyPieData = [];
  let pieChartLabel = [];
  if (!pieChartLoading) {
    pieChartLabel = pieChartData?.data?.map((pieData) => {
      return pieData?.meter_name;
    });
    activeEnergyPieData = pieChartData?.data?.map((pieData) => {
      return pieData.active_energy_consumption.split(" ")[0];
    });
    reactiveEnergyPieData = pieChartData?.data?.map((pieData) => {
      return pieData.reactive_energy_consumption.split(" ")[0];
    });
    apparentEnergyPieData = pieChartData?.data?.map((pieData) => {
      return pieData.apparent_energy_consumption.split(" ")[0];
    });
  }
  // console.log(pieChartLabel);

  // //mqtt code starts
  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8081,
  };

  let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);
  useEffect(() => {
    client.subscribe("/dashboard/data");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      energyDashboardDataFetch();
    });
  }, [client]);

  const clickHandler = (id) => {
    // console.log(id);
    const path = generatePath("/energy-meter/:meterId", {
      meterId: id,
    });
    navigate(path);
  };
  // // mqtt code ends

  // const roomClickHandler = (room_name) => {
  //   const path = generatePath("/dashboard/:roomId", { roomId: room_name });
  //   navigate(path);
  // };

  let energyDashboardData = "";
  let element = "";
  const types = {
    VLL: "Phase to Phase Voltage Average",
    VLN: "Phase to Neutral Voltage Average",
    IT: "Current Avg",
    PT: "Active Power Load",
    ST: "Apparent Power Load",
    QT: "Reactive Power Load",
    CT: "Power Factor Average",
    EPD: "Active Energy Delivered",
    EQD: "Reactive Energy Delivered",
    ESD: "Apparent Energy Delivered",
    ERST: "Accumulated Energy Reset Date/Time",
  };

  if (isLoading) {
    element = (
      <div className="container loading-center">
        <ReactLoading type="spin" color="#6d6dae" height={100} width={50} />
      </div>
    );
  }
  if (!isLoading) {
    if (results?.data) {
      data = results?.data || {};
    }
    // console.log("api-data");
    // console.log(data);

    // console.log((data && data) || {});
    energyDashboardData = data && Object.entries(data);
    // console.log(energyDashboardData);
    element = (
      <div className={`container-fluid ${theme}`}>
        <div style={{ paddingBottom: "10px" }}>
          <div class="badge bg-success" style={{ padding: "15px" }}>
            Carbon Emissions : {carbonEmmisions || 0} kg
          </div>
        </div>
        <div className="row">
          {energyDashboardData ? (
            energyDashboardData.map(([meter, value]) => {
              const [meterId, meterType] = meter?.split("|");

              let latestTimeStamp = new Date(value[0]?.device_timestamps);
              // console.log(latestTimeStamp);
              let time_Ago = "";
              if (latestTimeStamp) {
                time_Ago = timeAgo(latestTimeStamp);
              }
              return (
                <div class="col-md-4">
                  <div
                    className="room-card "
                    // onClick={() => roomClickHandler(roomId)}
                  >
                    <div className="card-header">
                      <div style={{ fontSize: "15px" }}>
                        Meter : {meterType}({time_Ago})
                      </div>
                      <div
                        className="details-text"
                        onClick={() => clickHandler(meterId)}
                      >
                        <TbListDetails />
                        details
                      </div>
                    </div>
                    <br></br>
                    <div className="">
                      <div className="theme-text">
                        <div>
                          {/* <h5>Meter-Id : {meterId}</h5> */}
                          <table style={{ width: "100%" }}>
                            {value?.map((item) => {
                              return (
                                <tr>
                                  <td className="energy-td">
                                    {types[item.unit_sl]}
                                  </td>
                                  <td className="energy-td">
                                    {item.value} {item.unit}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="theme-text">No Data Available</div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`container-fluid ${theme}`}
        style={{ paddingBottom: "1rem" }}
      >
        {/* <div className="row">
          <div className="col-md-4 ">
            <div className="room-card ">
              <div className="card-header">
                <div style={{ fontSize: "15px" }}>Active Energy(KWh)</div>
              </div>

              <div className="h-100 d-flex align-items-center justify-content-center">
                <PieChart
                  label={pieChartLabel}
                  pieData={activeEnergyPieData}
                  unit={"KWh"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className="room-card ">
              <div className="card-header">
                <div style={{ fontSize: "15px" }}>Reactive Energy(KVARh)</div>
              </div>

              <div className="h-100 d-flex align-items-center justify-content-center">
                <PieChart
                  label={pieChartLabel}
                  pieData={reactiveEnergyPieData}
                  unit={"KVARh"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className="room-card ">
              <div className="card-header">
                <div style={{ fontSize: "15px" }}>Apparent Energy(KVAh)</div>
              </div>

              <div className="h-100 d-flex align-items-center justify-content-center">
                <PieChart
                  label={pieChartLabel}
                  pieData={apparentEnergyPieData}
                  unit={"KVAh"}
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {element}
    </>
  );
}

export default EnergyMeterDashboardUpdated;
